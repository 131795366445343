import { googleLogout } from "@react-oauth/google";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { NavigationProp } from "../../utils/types";


export const Navbar = ({ func }: NavigationProp) => {
    const [imageURL, setImageURL] = useState<string | null>(null)
    const [username, setUsername] = useState<string | null>(null)
    const [email, setEmail] = useState<string | null>(null)
    const navigate = useNavigate()

    useEffect(() => {
        const image = localStorage.getItem("picture");
        const username = localStorage.getItem("name");
        const email = localStorage.getItem("email");
        setImageURL(image)
        setUsername(username)
        setEmail(email)
    }, [])


    const Logout = () => {
        localStorage.removeItem("name")
        localStorage.removeItem("exp")
        localStorage.removeItem("picture")
        localStorage.removeItem("email_verified")
        localStorage.removeItem("email")
        googleLogout()
        navigate("/login")
    }

    return (
        <div className="navbar-custom">
            <div className="topbar container-fluid">
                <div className="d-flex align-items-center gap-lg-2 gap-1">

                    {/* <!-- Topbar Brand Logo --> */}
                    <div className="logo-topbar">
                        {/* <!-- Logo light --> */}
                        <a href="index.html" className="logo-light">
                            <span className="logo-lg">
                                <img src="assets/images/partners.png" alt="logo" />
                            </span>
                            <span className="logo-sm">
                                <img src="assets/images/partners.png" alt="small logo" />
                            </span>
                        </a>

                        {/* <!-- Logo Dark --> */}
                        <a href="index.html" className="logo-dark">
                            <span className="logo-lg">
                                <img src="assets/images/partners.png" alt="dark logo" />
                            </span>
                            <span className="logo-sm">
                                <img src="assets/images/partners.png" alt="small logo" />
                            </span>
                        </a>
                    </div>

                </div>

                <ul className="topbar-menu d-flex align-items-center gap-3">

                    <li className="dropdown notification-list d-md-none d-sm-block">
                        <a className="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" href="#." role="button" aria-haspopup="true" aria-expanded="false">
                            <i className="ri-list-unordered font-22"></i>
                        </a>
                        <div className=" dropdown-menu dropdown-menu-end dropdown-menu-animated dropdown-lg py-0" style={{ zIndex: "999", maxHeight: "20rem", overflow: "auto", scrollbarWidth: "thin" }}>
                            <div className="p-2 border-top-0 border-start-0 border-end-0 border-dashed border">
                                <div className="row align-items-center">
                                    <h6 className="m-0 font-16 fw-bold"> Measurements </h6>
                                </div>
                            </div>
                            <div>
                                <ul className="p-0">
                                    <li className="dropdown-item ps-2">
                                        <a href="#Meteorological" style={{ color: "var(--ct-heading-color)" }} onClick={() => func!('weather')}> Meteorological </a>
                                    </li>
                                    <li className="dropdown-item ps-2">
                                        <a href="#Hydrological" style={{ color: "var(--ct-heading-color)" }} onClick={() => func!('hydro')}> Hydrological </a>
                                    </li>
                                    <li className="dropdown-item ps-2">
                                        <a href="#cml" style={{ color: "var(--ct-heading-color)" }} onClick={() => func!('cml')}> Commercial Microwave Links </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="p-2 border-top-1 border-start-0 border-end-0 border-dashed border">
                                <div className="row align-items-center">
                                    <h6 className="m-0 font-16 fw-bold"> Forecasts </h6>
                                </div>
                            </div>
                            <div>
                                <ul className="p-0">
                                    <li className="dropdown-item ps-2">
                                        <a href="#rainfall" style={{ color: "var(--ct-heading-color)" }} onClick={() => func!('rainfall')}> Rainfall </a>
                                    </li>
                                    <li className="dropdown-item ps-2">
                                        <a style={{ color: "var(--ct-heading-color)" }} href="#flood"> Flood </a>
                                        <div>
                                            <ul className="">
                                                <li className="p-1">
                                                    <a style={{ color: "var(--ct-heading-color)" }} href="#water-levels" onClick={() => func!('water-levels')}> Water Levels </a>
                                                </li>
                                                <li className="p-1">
                                                    <a style={{ color: "var(--ct-heading-color)" }} href="#flooded-areas" onClick={() => func!('flooded-areas')}> Flooded Areas </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>

                    <li className="dropdown">
                        <a className="nav-link dropdown-toggle arrow-none nav-user px-2" data-bs-toggle="dropdown" href="#!"
                            role="button" aria-haspopup="false" aria-expanded="false">
                            <span className="account-user-avatar">
                                {imageURL ? (<img src={imageURL} alt="user" width="32" className="rounded-circle" />) : (<img src="assets/images/user.png" alt="user" width="32" className="rounded-circle" />)}
                            </span>
                            <span className="d-lg-flex flex-column gap-1 d-none">
                                {username ? (<h5 className="my-0">{username}</h5>) : (<></>)}
                                {email ? (<h6 className="my-0 fw-normal">{email}</h6>) : (<>...</>)}
                            </span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated profile-dropdown">
                            <div className=" dropdown-header noti-title">
                                <h6 className="text-overflow m-0">Welcome !</h6>
                            </div>

                            <a href="login" onClick={() => Logout()} className="dropdown-item">
                                <i className="mdi mdi-logout me-1"></i>
                                <span>Logout</span>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}