import { Icon } from "leaflet";
import { MapContainer, TileLayer, Marker, GeoJSON, Popup } from "react-leaflet";
import { MapContainerComponentProp } from "../../utils/types";


export const MapContainerComponent = (props: MapContainerComponentProp) => {
    return (
        <MapContainer center={[6.709327322997796, -1.559240192997633]} zoom={12}>
            <TileLayer
                attribution='Google Maps'
                url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
            />
            {props.markers.length > 0 && props.markers.map((item: { code?: string, longitude: number, latitude: number, name: string }, index) => {
                let iconUrl;
                switch (props.trigger) {
                    case 0: // weather measurements
                        try {
                            iconUrl = require(`../../utils/MarkerIcon/${props.check[item.code!]}`);
                        } catch (err) {
                            iconUrl = require("../../utils/MarkerIcon/no_data.png");
                        }
                        break;
                    case 1: // rainfall forecast
                        try {
                            iconUrl = require(`../../utils/MarkerIcon/${props.check[item.name!]}`);
                        } catch (err) {
                            iconUrl = require("../../utils/MarkerIcon/no_data.png");
                        }
                        break;
                    case 2: // Hydro measurements
                        try {
                            iconUrl = require(`../../utils/MarkerIcon/${props.check[item.code!]}`);
                        } catch (err) {
                            iconUrl = require("../../utils/MarkerIcon/rainfall_no_data.png");
                        }
                        break;

                    default:
                        break;
                }

                const customIcon = new Icon({
                    iconUrl,
                })
                return (
                    <Marker key={index + item.name} position={[item.latitude, item.longitude]} eventHandlers={{
                        click: () => {
                            props.trigger === 1 ? props.offcanvasfunc({ name: item.name }) : (props.offcanvasfunc({ code: item.code }))
                        }
                    }} icon={customIcon}>
                        <Popup>{item.name}</Popup>
                    </Marker>
                )
            })}
            {props.geojsonFile && <GeoJSON data={props.geojsonFile} />}
        </MapContainer>
    )
}