import { useEffect, useState } from "react";
import "./Home.css";
import { Navbar } from "../../Components/Navbar/Navbar";
import { Sidebar } from "../../Components/Sidebar/Sidebar";
import "leaflet/dist/leaflet.css";
import basinData from '../../utils/AboaboBasin/Aboabo_Basin_GSMA.json';
import { GeoJsonObject } from 'geojson';
import { weatherStations } from "../../utils/TAHMO_Stations/weather_stations";
import { hydroStations } from "../../utils/TAHMO_Stations/hydro_stations";
import { rainfall } from "../../utils/Forecasts/rainfall";
import { Offcanvas } from "../../Components/Offcanvas/Offcanvas";
import { Legend } from "../../Components/Legend/Legend";
import { MapContainerComponent } from "../../Components/MapContainer/MapContainer";
import { Weather } from "../../utils/Functions/Weather";
import { RainfallForecast } from "../../utils/Functions/RainfallForecast";
import { Hydro } from "../../utils/Functions/Hydro";
import { googleLogout } from "@react-oauth/google";
import { useNavigate } from "react-router";


interface StationMakersTypes {
    name: string
    latitude: number
    longitude: number
    code?: string;
}

export const Home = () => {
    const [stationMarkers, setStationMarkers] = useState<StationMakersTypes[]>([])
    const [weatherDatasets, setWeatherDatasets] = useState<any>({})
    const [hydroDatasets, setHydroDatasets] = useState<any>({})
    const [rainfallForecasts, setRainfallForecasts] = useState<any>({})
    const [trigger, setTrigger] = useState(0)
    const [series, setSeries] = useState<ApexAxisChartSeries>([])
    const [geojsonFile, setGeoFile] = useState<GeoJsonObject | null>(null)
    const [title, setTitle] = useState("")
    const [active, setActive] = useState()
    const [colorCheck, setColorCheck] = useState<any>({})
    const [loading, setLoading] = useState("")
    const [maxx, setMaxx] = useState<number | undefined>(undefined)
    const [min, setMin] = useState<number | undefined>(undefined)
    const navigate = useNavigate()


    useEffect(() => {
        const exp = localStorage.getItem("exp")
        const email_verified = localStorage.getItem("email_verified")
        const today = new Date()
        if (new Date(Number(exp)) === today) {
            localStorage.removeItem("name")
            localStorage.removeItem("exp")
            localStorage.removeItem("picture")
            localStorage.removeItem("email_verified")
            googleLogout()
        } else if (email_verified !== "true") {
            navigate("/login")
        }
    }, [navigate])

    useEffect(() => {
    }, [series, stationMarkers, loading])

    useEffect(() => {
        const current = new Date()
        const start = new Date(current.setDate(current.getDate() - 7)).toISOString().split(':')[0] + ':00:00Z';
        const end = new Date().toISOString().split(':')[0] + ':00:00Z';
        const colors: any = {}
        const basicAuth = "Basic " + btoa(`${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`)

        const totalWeatherStationsResults: any = {}
        weatherStations.forEach((ele) => {
            const measurements_url = `${process.env.REACT_APP_DOMAIN}/api/fews-app/station_data?start=${start}&end=${end}&station_code=${ele.code}`;
            try {
                Weather(measurements_url, ele, totalWeatherStationsResults, colors, setLoading, setColorCheck)
            } catch (err) {
                alert("Oops, something went wrong")
                return
            }
        })

        const totalHydroStationsResults: any = {}
        hydroStations.forEach((ele) => {
            const measurements_url = `${process.env.REACT_APP_DOMAIN}/api/fews-app/station_data?start=${start}&end=${end}&station_code=${ele.code}`;
            try {
                Hydro(measurements_url, ele, totalHydroStationsResults, basicAuth, setLoading, colors)
            } catch (err) {
                alert("Oops, something went wrong")
                return
            }
        })

        const totalRainfallForecastResults: any = {}
        rainfall.forEach((ele) => {
            const namee = ele.name;
            try {
                const url = `${process.env.REACT_APP_DOMAIN}/api/fews-app/forecasts?geocode_lat=${ele.latitude}&geocode_long=${ele.longitude}`
                RainfallForecast(url, totalRainfallForecastResults, namee, colors, ele, setLoading)
            } catch (err) {
                alert("Oops, something went wrong")
                return
            }
        })

        setWeatherDatasets(totalWeatherStationsResults)
        setHydroDatasets(totalHydroStationsResults)
        setRainfallForecasts(totalRainfallForecastResults)
        setGeoFile(basinData as GeoJsonObject);
    }, [])

    const choose_station = (stations: string) => {
        setStationMarkers([])
        if (stations === "weather") {
            setTrigger(0)
            setStationMarkers(weatherStations)
        } else if (stations === "rainfall") {
            setTrigger(1)
            setStationMarkers(rainfall)
        } else if (stations === "hydro") {
            setTrigger(2)
            setStationMarkers(hydroStations)
        } else {
        }
    }

    const offcanvasDisplay = (obj: { name?: string, code?: string }) => {
        try {
            if ("code" in obj) {
                if (obj.code?.startsWith('TH')) {
                    setTitle(hydroDatasets[obj['code']!]['name'])
                    setSeries(hydroDatasets[obj['code']!]['variable']['wl'])
                    setMaxx(hydroDatasets[obj['code']!]['maxx'])
                    setMin(hydroDatasets[obj['code']!]['min'])
                    setActive(hydroDatasets[obj['code']!]['variable'])
                } else {
                    setTitle(weatherDatasets[obj['code']!]['name'])
                    setSeries(weatherDatasets[obj['code']!]['variables']['pr'])
                    setActive(weatherDatasets[obj['code']!]['variables'])
                }
            } else if ("name" in obj) {
                setTitle(rainfallForecasts[obj['name']!]['name'])
                setSeries(rainfallForecasts[obj['name']!]['data'])
            }

            const btn = document.getElementById('trigger') as HTMLButtonElement;
            const offcanvas = document.getElementById('offcanvasBottom');
            if (!(offcanvas?.classList.contains('show'))) {
                btn.click()
            }
            if ("code" in obj && obj.code?.startsWith('TA')) {
                const select = document.getElementById('varr') as HTMLSelectElement;
                select.value = "pr"
            }
        }
        catch (err) {
            const errDiv = document.createElement('div');
            errDiv.innerText = `No data available`;
            errDiv.id = 'flash-message';
            errDiv.className = 'flash-message';
            const content = document.getElementById("content")
            content?.appendChild(errDiv)
        }
    }

    return (
        <div className="wrapper">
            {loading.length < 12 ? (
                <div id="preloader" style={{ opacity: "0.9" }}>
                    <div id="status" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <span className="h3">Loading</span>
                        <div className="bouncing-loader">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            ) : (<>
                <Navbar func={choose_station} />
                <Sidebar func={choose_station} />
                <Legend trigger={trigger} />
                <div id="content" className="content-page">
                    <div className="content" style={{ minHeight: 0 }}>
                        <div className="container-fluid">
                            <div className="row">
                                <MapContainerComponent markers={stationMarkers} check={colorCheck} trigger={trigger} geojsonFile={geojsonFile} offcanvasfunc={offcanvasDisplay} />
                                <Offcanvas series={series} title={title} trigger={trigger} data={active} set={setSeries} maxx={maxx} min={min} />
                            </div>
                        </div>
                    </div>
                </div>
            </>)}
        </div>
    )
}