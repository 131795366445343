import { ApexOptions } from "apexcharts"
import { useEffect } from "react";
import Chart from "react-apexcharts";
import "./Offcanvas.css"
import { OffcanvasProps } from "../../utils/types";


export const Offcanvas = ({ series, title, trigger, data, set, maxx, min }: OffcanvasProps) => {


    useEffect(() => {
        const closeIt = document.getElementById('close') as HTMLButtonElement;
        closeIt.click()
    }, [trigger])

    const chooseVarData = (varr: string) => {
        const selectVar = document.getElementById(varr) as HTMLSelectElement;
        if (set !== undefined) {
            set(data[selectVar.value])
        }
    }

    const options: ApexOptions = {
        title: {
            text: title + (trigger === 1 ? " - a five-day rainfall forecast starting today" : trigger === 2 ? (" - (Water Levels)") : ""),
            align: 'center',
            style: {
                fontSize: '20px',
                fontWeight: 'bold',
                color: '#263238'
            },
        },
        // annotations: {
        //     yaxis: [
        //         {
        //             y: 2,
        //             borderColor: '#ffd100',
        //             strokeDashArray: 0,
        //         },
        //         {
        //             y: 3,
        //             borderColor: '#ff9100',
        //             strokeDashArray: 0,
        //         },
        //         {
        //             y: 4,
        //             borderColor: '#ff0000',
        //             strokeDashArray: 0,
        //         },
        //     ]
        // },
        fill: {
            opacity: 0.2
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 1
        },
        yaxis: {
            labels: {
                formatter: (value) => { return value.toFixed(2) + "" }
            },
            max: trigger === 2 ? maxx : undefined,
            min: trigger === 2 ? min : undefined
        },
        xaxis: {
            type: "datetime",
            labels: {
                datetimeFormatter: {
                    year: 'yyyy',
                    month: 'MMM \'yy',
                    day: 'dd MMM',
                    hour: 'HH:mm'
                }
            }
        }
    }

    return (
        <div className="p-0 offcanvas offcanvas-bottom" data-bs-scroll="true" data-bs-backdrop="false" style={{ marginLeft: "var(--ct-leftbar-width)", height: "35vh", width: "calc(100vw - var(--ct-leftbar-width))" }} tabIndex={-1} id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">


            {trigger === 0 ? (
                <div className="offcanvas-header p-1 pe-3">
                    <select className="form-select m-2 mb-0 mt-0" style={{ width: "25%" }} onChange={() => chooseVarData("varr")} id="varr">
                        <option value="pr">Precipitation</option>
                        <option value="te">Temperature</option>
                        <option value="rh">Relative Humidity</option>
                        <option value="ra">Radiation</option>
                        <option value="ws">Wind Speed</option>
                    </select>
                    <button id="close" type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
            ) : (
                <div className="offcanvas-header p-1 pe-3 justify-content-end">
                    <button id="close" type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
            )}

            <div className="offcanvas-body p-0">
                <div className="col-12" style={{ height: "90%" }}>
                    <Chart
                        options={options}
                        series={series}
                        type={trigger === 1 ? "bar" : "area"}
                        width="98%"
                        height="100%"
                        className="apex-charts m-0"
                    />
                </div>
            </div>
        </div>
    )
}