type trig = {
    trigger: number
}

export const Legend = ({ trigger }: trig) => {
    return (
        <div className="card shadow-lg" style={{ position: "absolute", width: "200px", maxHeight: "270px", top: "150px", right: "12px", zIndex: "401", backgroundColor: "#eef5f5" }}>
            <div className="card-body p-2">
                <div className="card-title"><h3 className="m-0">Legend</h3></div>
                {trigger === 0 || trigger === 1 ? (
                    <>
                        <div className="card-subtitle"><h5>Rainfall Thresholds</h5></div>
                        <hr className="m-0" />
                        <div >
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item border-0 p-1 d-flex" style={{ backgroundColor: "#eef5f5", fontSize: "larger" }}>
                                    <img alt="marker" style={{ width: "25px" }} src="/assets/markers/small.png" /> <span className="small" style={{ display: "contents" }}>&lt;&#160; 10 mm/day</span>
                                </li>
                                <li className="list-group-item border-0 p-1 d-flex" style={{ backgroundColor: "#eef5f5", fontSize: "larger" }}>
                                    <img alt="marker" style={{ width: "25px" }} src="/assets/markers/okay.png" /> <span className="small" style={{ display: "contents" }}>&gt;= 10 mm/day</span>
                                </li>
                                <li className="list-group-item border-0 p-1 d-flex" style={{ backgroundColor: "#eef5f5", fontSize: "larger" }}>
                                    <img alt="marker" style={{ width: "25px" }} src="/assets/markers/warning.png" /> <span className="small" style={{ display: "contents" }}>&gt;= 25 mm/day</span>
                                </li>
                                <li className="list-group-item border-0 p-1 d-flex" style={{ backgroundColor: "#eef5f5", fontSize: "larger" }}>
                                    <img alt="marker" style={{ width: "25px" }} src="/assets/markers/danger.png" /> <span className="small" style={{ display: "contents" }}>&gt;= 50 mm/day</span>
                                </li>
                                <li className="list-group-item border-0 p-1 d-flex" style={{ backgroundColor: "#eef5f5", fontSize: "larger" }}>
                                    <img alt="marker" style={{ width: "25px" }} src="/assets/markers/no_data.png" /> <span className="small" style={{ display: "contents" }}>&#160; No data</span>
                                </li>
                            </ul>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="card-subtitle"><h5>Water Level Thresholds</h5></div>
                        <hr className="m-0" />
                        <div >
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item border-0 p-1 d-flex" style={{ backgroundColor: "#eef5f5", fontSize: "larger" }}>
                                    <img alt="marker" style={{ width: "25px" }} src="/assets/markers/rainfall_okay.png" /> <span className="small" style={{ display: "contents" }}>Below Threshold Level</span>
                                </li>
                                <li className="list-group-item border-0 p-1 d-flex" style={{ backgroundColor: "#eef5f5", fontSize: "larger" }}>
                                    <img alt="marker" style={{ width: "25px" }} src="/assets/markers/rainfall_warning.png" /> <span className="small" style={{ display: "contents" }}>Warning Level 1</span>
                                </li>
                                <li className="list-group-item border-0 p-1 d-flex" style={{ backgroundColor: "#eef5f5", fontSize: "larger" }}>
                                    <img alt="marker" style={{ width: "25px" }} src="/assets/markers/rainfall_danger.png" /> <span className="small" style={{ display: "contents" }}>Warning Level 2</span>
                                </li>
                                <li className="list-group-item border-0 p-1 d-flex" style={{ backgroundColor: "#eef5f5", fontSize: "larger" }}>
                                    <img alt="marker" style={{ width: "25px" }} src="/assets/markers/rainfall_no_data.png" /> <span className="small" style={{ display: "contents" }}>No data</span>
                                </li>
                            </ul>
                        </div>
                    </>
                )}
            </div>
            <button id="trigger" type="button" className="btn" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom" style={{ display: "none" }}></button>
        </div>
    )
}