import React from "react";
import axios from "axios";
import { rainfall } from "../Forecasts/rainfall";
import { Element } from "../types";

type element = Omit<Element, "code">

export const RainfallForecast = (
    url: string, totalRainfallForecastResults: any, namee: string, colors: any, ele: element, setLoading: React.Dispatch<React.SetStateAction<string>>
) => {
    axios.get(url)
        .then((res: any) => res.data)
        .then((rainfall_data) => {
            const precipData = rainfall_data['qpf']
            const validTimeLocal = rainfall_data['validTimeLocal']

            const dt_da: any = []
            for (let i = 0; i < validTimeLocal.length; i++) {
                dt_da.push([validTimeLocal[i].split('T')[0], precipData[i]])
            }
            if (dt_da[1].length === 0) {
                totalRainfallForecastResults[namee] = null
                colors[namee] = "no_data.png"
            }
            else {
                totalRainfallForecastResults[namee] = { name: ele.name, data: [{ name: "Rainfall Forecast", data: dt_da }] }
                if (Math.max(...dt_da[1]) >= 50) {
                    colors[namee] = "danger.png"
                } else if (Math.max(...dt_da[1]) >= 25 && Math.max(...dt_da[1]) < 50) {
                    colors[namee] = "warning.png"
                } else if (Math.max(...dt_da[1]) >= 10 && Math.max(...dt_da[1]) < 25) {
                    colors[namee] = "okay.png"
                } else {
                    colors[namee] = "small.png"
                }
            }
        })
        .catch(err => {
            totalRainfallForecastResults[namee] = { name: ele.name, data: [{ name: "Rainfall Forecast", data: [] }] }
            totalRainfallForecastResults[namee] = null
            colors[namee] = "no_data.png"
        })
        .finally(() => {
            if (ele.name === rainfall[rainfall.length - 1].name) {
                setLoading(prev => prev + "load")
            }
        })
}