// import { useEffect } from "react";
import "./Sidebar.css"
import { NavigationProp } from "../../utils/types";


export const Sidebar = ({ func }: NavigationProp) => {
    return (
        <div className="leftside-menu">
            <a href="/" className="logo logo-light p-0">
                <span className="logo-lg">
                    <img src="/assets/images/partners.png" style={{ height: "4rem" }} alt="logo" />
                </span>
                <span className="logo-sm">
                    <img src="/assets/images/partners.png" alt="small logo" />
                </span>
            </a>
            <a href="/" className="logo logo-dark">
                <span className="logo-lg">
                    <img src="/assets/images/partners.png" style={{ height: "4rem" }} alt="logo" />
                </span>
                <span className="logo-sm">
                    <img src="/assets/images/partners.png" alt="small logo" />
                </span>
            </a>
            <div className="button-sm-hover" data-bs-toggle="tooltip" data-bs-placement="right" title="Show Full Sidebar">
                <i className="ri-checkbox-blank-circle-line align-middle"></i>
            </div>
            <div className="button-close-fullsidebar">
                <i className="ri-close-fill align-middle"></i>
            </div>
            <div className="h-100 pt-3" id="leftside-menu-container" data-simplebar>
                <ul className="side-nav">
                    <li className="side-nav-item">
                        <a data-bs-toggle="collapse" href="#measurements" aria-expanded="false"
                            aria-controls="measurements" className="side-nav-link">
                            <i className="uil-layer-group"></i>
                            <span> Measurements </span>
                            <span className="menu-arrow"></span>
                        </a>
                        <div className="collapse" id="measurements">
                            <ul className="side-nav-second-level">
                                <li>
                                    <a href="#Meteorological" onClick={() => func!('weather')}> Meteorological </a>
                                </li>
                                <li>
                                    <a href="#Hydrological" onClick={() => func!('hydro')}> Hydrological </a>
                                </li>
                                <li>
                                    <a href="#cml" onClick={() => func!('cml')}> Commercial Microwave Links </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <hr style={{ opacity: "0.3" }} />
                    <li className="side-nav-item">
                        <a data-bs-toggle="collapse" href="#sidebarMultiLevel" aria-expanded="false"
                            aria-controls="sidebarMultiLevel" className="side-nav-link">
                            <i className="uil-layer-group"></i>
                            <span> Forecasts </span>
                            <span className="menu-arrow"></span>
                        </a>
                        <div className="collapse" id="sidebarMultiLevel">
                            <ul className="side-nav-second-level">
                                <li className="side-nav-item">
                                    <a href="#rainfall" onClick={() => func!('rainfall')}>
                                        <i className="uil-cloud-sun-tear"></i>
                                        <span> Rainfall </span>
                                    </a>
                                </li>
                                <li className="side-nav-item">
                                    <a data-bs-toggle="collapse" href="#sidebarThirdLevel" aria-expanded="false"
                                        aria-controls="sidebarThirdLevel">
                                        <i className="uil-water"></i>
                                        <span> Flood </span>
                                        <span className="menu-arrow"></span>
                                    </a>
                                    <div className="collapse" id="sidebarThirdLevel">
                                        <ul className="side-nav-third-level">
                                            <li>
                                                <a href="#water-levels" onClick={() => func!('water-levels')}>Water Levels</a>
                                            </li>
                                            <li>
                                                <a href="#flooded-areas" onClick={() => func!('flooded-areas')}>Flooded Areas</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <hr style={{ opacity: "0.3" }} />
                    <li className="side-nav-item">
                        <a href="#help" className="side-nav-link">
                            <i className="uil-comment-info"></i>
                            <span> Help? </span>
                        </a>
                    </li>
                </ul>

                <div className="clearfix"></div>
            </div>
        </div>
    )
}
