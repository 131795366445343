export const hydroStations = [
    {
        name: "Anloga Junction Dwellers Gold Church",
        latitude: 6.688502,
        longitude: -1.597817,
        code: "TH00017"
    },
    {
        name: "KNUST Botenical Garden",
        latitude: 6.684969,
        longitude: -1.562032,
        code: "TH00018"
    },
    {
        name: "Atonsu Allied Oil Fuel Station",
        latitude: 6.658347,
        longitude: -1.595184,
        code: "TH00019"
    },
    {
        name: "ICON Oil Fuel Station",
        latitude: 6.732016,
        longitude: -1.570953,
        code: "TH00020"
    },
    {
        name: "Miracle Temple Church of Pentecost",
        latitude: 6.67374,
        longitude: -1.592368,
        code: "TH00021"
    }

]