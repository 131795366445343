import React from "react";
import axios from "axios";
import { hydroStations } from "../TAHMO_Stations/hydro_stations";
import { Element } from "../types";


export const Hydro = (
	measurements_url: string, ele: Element, totalHydroStationsResults: any, basicAuth: string, setLoading: React.Dispatch<React.SetStateAction<string>>,
	colors: any
) => {
	axios.get(measurements_url, {
		headers: {
			"Authorization": basicAuth
		}
	})
		.then((res: any) => res.data)
		.then((measurements_results: any) => {
			const valueInd = measurements_results['results'][0]['series'][0]['columns'].indexOf('value');
			const timeInd = measurements_results['results'][0]['series'][0]['columns'].indexOf('time');
			const varInd = measurements_results['results'][0]['series'][0]['columns'].indexOf('variable');

			const values: [] = measurements_results['results'][0]['series'][0]['values']

			const variable: any = {
				wl: [{ name: "Water Level", data: [] }]
			}

			const wl_vals: any = []

			values.forEach((element: any) => {
				if (element[varInd] in variable) {
					if (element[valueInd] === null) {
						variable[element[varInd]][0]['data'].push([element[timeInd], 0])
					} else {
						variable[element[varInd]][0]['data'].push([element[timeInd], element[valueInd]])
					}
					wl_vals.push(element[valueInd])
				}
			})

			let dangerCheck = Math.max(...wl_vals)

			if (variable['wl'][0]['data'].length === 0) {
				totalHydroStationsResults[ele.code] = null
				colors[ele.code] = "rainfall_no_data.png"
			} else {
				if (dangerCheck >= 4) {
					colors[ele.code] = "rainfall_danger.png"
				} else if (dangerCheck >= 2 && dangerCheck < 4) {
					colors[ele.code] = "rainfall_warning.png"
				} else if (dangerCheck < 2) {
					colors[ele.code] = "rainfall_okay.png"
				} else {
					colors[ele.code] = "rainfall_no_data.png"
				}
			}

			totalHydroStationsResults[ele.code] = { name: ele.name, variable: variable, maxx: dangerCheck, min: Math.min(...wl_vals) }
		})
		.catch(err => {
			totalHydroStationsResults[ele.code] = { name: ele.name, variable: { wl: [{ name: "Water Level", data: [] }] } }
			totalHydroStationsResults[ele.code] = null
			colors[ele.code] = "rainfall_no_data.png"
		})
		.finally(() => {
			if (ele.name === hydroStations[hydroStations.length - 1].name) {
				setLoading(prev => prev + "load")
			}
		})
}