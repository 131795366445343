import { CredentialResponse, GoogleLogin, googleLogout } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { UserData } from '../../utils/types';
import { useNavigate } from 'react-router';


export const Login = () => {
    const [error, setError] = useState<string | null>(null)
    const navigate = useNavigate()

    useEffect(() => {
        const exp = localStorage.getItem("exp")
        const email_verified = localStorage.getItem("email_verified")
        const today = new Date()
        if (new Date(Number(exp)) === today) {
            localStorage.removeItem("name")
            localStorage.removeItem("exp")
            localStorage.removeItem("picture")
            localStorage.removeItem("email_verified")
            localStorage.removeItem("email")
            googleLogout()
        } else if (email_verified === "true") {
            navigate("/")
        }
    }, [error, navigate])

    const loginUser = (token: string) => {
        const userObject: UserData = jwtDecode(token)
        if (userObject.email_verified === true) {
            const dt = new Date();
            dt.setDate(dt.getDate() + 7);
            localStorage.setItem("name", userObject.name);
            localStorage.setItem("exp", String(dt.getTime()));
            localStorage.setItem("email_verified", String(userObject.email_verified));
            localStorage.setItem("email", userObject.email);
            localStorage.setItem("picture", userObject.picture);
            navigate('/')
        } else {
            setError("Failed to verify email.")
        }
    }


    return (<>
        <div className="position-absolute start-0 end-0 start-0 bottom-0 vw-100 vh-100">
            <img src="assets/images/background.jpg" alt="background" className='w-100 h-100' style={{ objectFit: "cover" }} />
        </div>
        <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5 position-relative d-flex align-items-center" style={{ height: "100vh" }}>
            <div className="container">
                {error ? (<div className='d-flex justify-content-center'><div className='alert alert-danger' style={{ width: "25rem" }}>{error}</div></div>) : (<></>)}
                <div className="row justify-content-center">
                    <div className="col-xxl-4 col-lg-5">
                        <div className="card">
                            <div className="card-header py-4 text-center bg-dark">
                                <span><img src="assets/images/partners.png" alt="logo" height="50" /></span>
                            </div>

                            <div className="card-body p-4">

                                <div className="text-center w-75 m-auto">
                                    <h4 className="text-dark-50 text-center pb-0 fw-bold">Flood Early Warning System</h4>
                                    <p className="text-muted mb-4">Login in with your email address to continue.</p>
                                </div>

                                <div className='d-flex justify-content-center'>
                                    <GoogleLogin
                                        onSuccess={(credentialResponse: CredentialResponse) => {
                                            loginUser(credentialResponse.credential!)
                                        }}
                                        onError={() => {
                                            setError("Try logging in again. Something went wrong")
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </>)
}