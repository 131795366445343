import React from "react";
import axios from "axios";
import { weatherStations } from "../TAHMO_Stations/weather_stations";
import { Element } from "../types";


export const Weather = (
    measurements_url: string, ele: Element, totalWeatherStationsResults: any, colors: any, setLoading: React.Dispatch<React.SetStateAction<string>>, setColorCheck: React.Dispatch<any>
) => {
    axios.get(measurements_url)
        .then((res: any) => res.data)
        .then((measurements_results: any) => {
            const valueInd = measurements_results['results'][0]['series'][0]['columns'].indexOf('value');
            const timeInd = measurements_results['results'][0]['series'][0]['columns'].indexOf('time');
            const varInd = measurements_results['results'][0]['series'][0]['columns'].indexOf('variable');

            const values: [] = measurements_results['results'][0]['series'][0]['values']

            const variables: any = {
                pr: [{ name: "Precipitation", data: [] }],
                te: [{ name: "Temperature", data: [] }],
                rh: [{ name: "Relative Humidity", data: [] }],
                ws: [{ name: "Wind Speed", data: [] }],
                ra: [{ name: "Radiation", data: [] }]
            }
            const precip: any[] = []
            values.forEach((element: any) => {
                if (element[varInd] in variables) {
                    if (element[varInd] !== "pr") {
                        variables[element[varInd]][0]['data'].push([element[timeInd], element[valueInd]])
                    } else {
                        precip.push(element)
                    }
                }
            })

            const precipObject: any = {}

            precip.forEach(ele => {
                if (ele[timeInd].split('T')[0] in precipObject) {
                    precipObject[ele[timeInd].split('T')[0]] += ele[valueInd]
                } else {
                    precipObject[ele[timeInd].split('T')[0]] = ele[valueInd]
                }
            })
            const vals: number[] = Object.values(precipObject)
            let dangerCheck = Math.max(...vals)
            const final: Object[] = []
            for (let i = 0; i < Object.keys(precipObject).length; i++) {
                final.push([Object.keys(precipObject)[i], Object.values(precipObject)[i]])
            }
            variables['pr'][0]['data'] = final

            if (variables['pr'][0]['data'].length === 0) {
                totalWeatherStationsResults[ele.code] = null
                colors[ele.code] = "no_data.png"
            } else {
                if (dangerCheck >= 50) {
                    colors[ele.code] = "danger.png"
                } else if (dangerCheck >= 25 && dangerCheck < 50) {
                    colors[ele.code] = "warning.png"
                } else if (dangerCheck >= 10 && dangerCheck < 25) {
                    colors[ele.code] = "okay.png"
                } else {
                    colors[ele.code] = "small.png"
                }


                totalWeatherStationsResults[ele.code] = { name: ele.name, variables: variables }
            }
            setColorCheck(colors)
        })
        .catch(err => {
            totalWeatherStationsResults[ele.code] = {
                name: ele.name, variable: {
                    pr: [{ name: "Precipitation", data: [] }],
                    te: [{ name: "Temperature", data: [] }],
                    rh: [{ name: "Relative Humidity", data: [] }],
                    ws: [{ name: "Wind Speed", data: [] }],
                    ra: [{ name: "Radiation", data: [] }]
                }
            }
            totalWeatherStationsResults[ele.code] = null
            colors[ele.code] = "rainfall_no_data.png"
        })
        .finally(() => {
            if (ele.name === weatherStations[weatherStations.length - 1].name) {
                setLoading(prev => prev + "load")
            }
        })
}
