export const rainfall = [
    {
        name: "Aboabo No. 1 Pelele",
        latitude: 6.699585,
        longitude: -1.601145,
    },
    {
        name: "Miracle Temple Church of Pentecost",
        latitude: 6.67374,
        longitude: -1.592368,
    },
    {
        name: "ICON Oil Fuel Station Duase",
        latitude: 6.732016,
        longitude: -1.570953
    },
    {
        name: "KNUST Botenical Garden",
        latitude: 6.684969,
        longitude: -1.562032,
    },
    {
        name: "Atonsu Allied Oil Fuel Station",
        latitude: 6.658347,
        longitude: -1.595184,
    },
    {
        name: "Anloga Junction Dwellers Gold Church",
        latitude: 6.688502,
        longitude: -1.597817,
    },
    {
        name: "Prempeh Assembly Hall",
        latitude: 6.6938011,
        longitude: -1.6147393,
    },
    {
        name: "WESCO",
        latitude: 6.7146553,
        longitude: -1.6234614,
    },
    {
        name: "Kumasi Academy",
        latitude: 6.7087753,
        longitude: -1.5707259,
    },
    {
        name: "Garden City University",
        latitude: 6.7398743,
        longitude: -1.566275,
    },
    {
        name: "Duase",
        latitude: 6.7321502,
        longitude: -1.5731357,
    }
]